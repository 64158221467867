<template>
  <div v-if="data?.statistieken">
    <small>Dit zijn de <b>promotiecodes</b> gebruikt in acties <span class="italic">(zonder vouchers, zonder waardebonnen)</span></small>
    <UITableResponsive :items="data.statistieken || []" class="text-center w-auto" :headers="['Datum', 'Code', 'Omzet']">
      <template v-slot:colgroup>
        <colgroup>
          <col class="w-0" />
          <col class="w-0" />
          <col class="w-auto" />
        </colgroup>
      </template>
      <template v-slot:item-Datum="{ item }">
        <div class="flex flex-row gap-1">
          <span v-if="item.day">{{ item.day }}</span>
          <span v-if="item.month">{{ monthToString(item.month) }}</span>
          <span v-if="item.year">{{ item.year }}</span>
        </div>
      </template>
      <template v-slot:item-Code="{ item }">
        <small v-if="!item.code" class="italic text-gray-500">Geen code(s)</small>
        <span v-else>{{ item.code }}</span>
      </template>
      <template v-slot:item-Omzet="{ item }">
        &euro;&nbsp;{{ formatPrice(item.omzet) }}
      </template>
    </UITableResponsive>
  </div>
</template>

<script setup>
import useGetApi from '@/hooks/useGetApi'
import { monthToString } from '@/functions/formatDate'
import { formatPrice } from '@/functions/formatNumber'

import UITableResponsive from '@/components/UI/Table/Responsive'

const props = defineProps({
  filters: {
    type: Object,
    required: true,
  },
})

const { data } = useGetApi('/api/dashboard/stats/promoties', props.filters, { watch: true })
</script>
